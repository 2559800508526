import { Flex, Heading, Image, Stack, Text } from '@chakra-ui/react'
import './IntelligenceCard.css'
import { gsap } from 'gsap'
import { useEffect } from 'react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export type IntelProps = {
  title: string
  description: string
  image: string
  url: string
  count: number
}

export function IntelligenceCard(props: IntelProps) {
  const { title, description, image, count } = props

  const animateFromTo = (elem: any, direction: any) => {
    const offset = 1000
    let x = 0
    let y = direction * offset

    direction = direction | 1

    if (elem.classList.contains('slide_from_left')) {
      x = -offset
      y = 0
    } else if (elem.classList.contains('slide_from_right')) {
      x = offset
      y = 0
    }

    gsap.fromTo(
      elem,
      { x: x, y: y, autoAlpha: 0 },
      {
        duration: 2.5,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: 'expo',
        overwrite: 'auto',
      }
    )
  }

  // const hide = (elem: any) => {
  //   gsap.set(elem, { autoAlpha: 0 })
  // }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    gsap.utils.toArray('.animate').forEach(function (elem: any) {
      // hide(elem)

      ScrollTrigger.create({
        trigger: elem,
        onEnter: function () {
          animateFromTo(elem, 1)
        },
        // onEnterBack: function () {
        //   animateFromTo(elem, -1)
        // },
        // onLeave: function () {
        //   hide(elem)
        // },
      })
    })
  }, [])

  return (
    <Flex
      alignItems="center"
      gap="2"
      direction={{ base: 'column', lg: count % 2 === 0 ? 'row-reverse' : 'row' }}
      my={'1rem'}
      minW={{ base: 'md', md: '5xl' }}
      className={`animate ${count % 2 === 0 ? 'slide_from_right' : 'slide_from_left'}`}
    >
      <Stack
        borderRadius="lg"
        w={{ base: '80%', md: '680px' }}
        height={{ base: '226px', md: '20rem' }}
        direction={{ base: 'column', md: 'row' }}
        bg={'gray.100'}
        boxShadow={'2xl'}
        p={4}
        flexDirection={count % 2 === 0 ? 'row-reverse' : 'row'}
        justifyContent="flex-start"
      >
        <Flex flex={1} bg="transparent" p={'2'}>
          <Image objectFit="contain" boxSize={'100%'} src={image} />
        </Flex>
        <Stack flex={1} flexDirection="column" justifyContent="flex-start" alignItems="center" px={{ base: 0, lg: 1 }}>
          <Heading fontSize={{ base: 'md', md: '3xl' }} color={'primary.500'} textAlign={'center'}>
            {title}
          </Heading>
          <Text textAlign={'left'} fontSize={{ base: 'xs', md: 'lg' }} color={'black'}>
            {description}
          </Text>
          <Stack
            width={'100%'}
            mt={'2rem'}
            direction={'row'}
            padding={2}
            justifyContent={'space-between'}
            alignItems={'center'}
            display={{ base: 'none', md: 'inherit' }}
          ></Stack>
        </Stack>
      </Stack>
    </Flex>
  )
}
