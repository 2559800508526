import { Box, Heading, Text, Stack, Image } from '@chakra-ui/react'
import { scholar, royal, hood, neophyte } from '../assets/images'

type TCharacter = {
  name: string
  image: string
  desc: string
}

export default function CharacterCard() {
  return (
    <>
      <Heading
        textAlign={'center'}
        mb={6}
        lineHeight={1.1}
        fontWeight={600}
        fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}
      >
        AK3NIAN{' '}
        <Text as={'span'} color={'secondary.500'} fontStyle={'oblique'}>
          [AH-KEE-NIAN]
        </Text>{' '}
        SOCIETY
      </Heading>

      <Stack direction={{ base: 'column', md: 'row' }} py={'30px'} alignItems={'center'}>
        {characters.map((char: TCharacter) => {
          return (
            <Box
              maxW={'445px'}
              w={'full'}
              bg={'white'}
              _dark={{
                bg: 'gray.700',
              }}
              boxShadow={'2xl'}
              rounded={'md'}
              p={6}
              overflow={'hidden'}
              key={char.name}
            >
              <Stack
                bg={'gray.100'}
                mt={{ base: '-8', md: '-8' }}
                mx={{ base: '-6', md: '-6' }}
                mb={{ base: '2', md: '5' }}
                alignItems={'center'}
              >
                <Image src={char.image} objectFit={'fill'} boxSize={'250px'} />
              </Stack>
              <Stack>
                <Heading color={'primary.500'} fontSize={'3xl'} fontFamily={'body'}>
                  {char.name}
                </Heading>
                <Text color={'gray.500'} _dark={{ color: 'gray.200' }} noOfLines={7}>
                  {char.desc}
                </Text>
              </Stack>
            </Box>
          )
        })}
      </Stack>
    </>
  )
}

const characters: TCharacter[] = [
  {
    name: 'Royals',
    image: royal,
    desc: 'The Royals are a proud and prudent species donning a golden crown. They are the existing elite of Ak3demix and receive gracious tributes and gifts from the other communities. Heavy is the head that wears the crown.',
  },
  {
    name: 'Scholars',
    image: scholar,
    desc: 'The Scholars are a learned and enlightened segment of Ak3demix. They serve at the leisure of the Royals constantly looking to enhance the kingdom through research and development with the Seeds of Knowledge. They are the scientists, doctors, lawyers, and economists in society; but most importantly they are the guardians of Ultimate Intelligence.',
  },
  {
    name: 'Hoods',
    image: hood,
    desc: 'A well respected (not-so-secret) society of hooded Ak3s, the Demis are a deep undercurrent in Ak3demix. All communities know of their existence, but do not know why or who are behind the hoods. There are rumors of power plays with the Royals and cultish type rituals held in dark forests and the depths of eerie mines.',
  },
  {
    name: 'Neophytes',
    image: neophyte,
    desc: 'The Neophytes are the majority of Ak3demix and recognized by the lack of headgear. The Neophytes are peaceful and cooperative by nature and they take great care to preserve and promote the society and protect it for future generations. Their creativity and dedication to Ak3demix have made them a respected and valued.',
  },
]
