import { Heading, Select, Button, Container, Highlight, Text, Box, Flex } from '@chakra-ui/react'
import { ethers } from 'ethers'
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi'
import networkMapping from '../assets/constants/network-mapping.json'
import HGManager_ABI from '../assets/constants/HeadgearManager.json'
import { useState } from 'react'

const DaoAdmin = () => {
  const [selectedDao, setSelectedDao] = useState('0')

  const { config, error: pmError } = usePrepareContractWrite({
    address: networkMapping['43113'].HeadgearManager as `0x${string}`,
    abi: HGManager_ABI,
    functionName: 'postMintDistribution',
    args: [['2'], Number(selectedDao)],
  })

  const { data, write: postMintDistribution } = useContractWrite(config)

  const { isLoading, isError, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  })

  return (
    <Box color={'gray.800'} minH="85vh">
      <Flex py={12} direction="column" justifyContent="space-evenly" alignItems="center">
        <Container>
          <Heading>Dao Admin [Only for Testing]</Heading>
          <Text>
            <Highlight styles={{ color: 'primary.500' }} query={'0xf75e71d9191b523385FC4d33D6EeCA942Fdcb406'}>
              All Functions on this page will only work when from 0xf75e71d9191b523385FC4d33D6EeCA942Fdcb406
            </Highlight>
          </Text>
        </Container>
        <Container my={'12px'} w={'md'}>
          <Select placeholder="Select Dao" onChange={(e) => setSelectedDao(e.target.value)}>
            <option value="1">NEO DAO</option>
            <option value="2">SCHOLAR DAO</option>
            <option value="3">HOOD DAO</option>
            <option value="4">ROYAL DAO</option>
          </Select>
          {pmError && <Text>{pmError.message}</Text>}
          <Button onClick={postMintDistribution}>Set Post Mint Distribution</Button>
        </Container>
      </Flex>
    </Box>
  )
}
export default DaoAdmin
