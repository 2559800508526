export type TDaoInfo = {
  Symbol: string
  Lore: string
  Quorum: string
  'Voting Period': string
  'Total Members': number
  'Total Proposals': number
  'Mint Price': string
  'Total Supply': string
}

export enum ProposalType {
  MEMBERSHIP,
  SHARES,
  CALL,
  EXTENSION,
  ESCAPE,
  LORE,
}

export type TProposal = {
  yesVotes: number
  noVotes: number
  totalVotes: number
  creationTime: string
  proposalType: string
  proposer: string
  accounts: string[]
  amounts: string[]
  payloads: any[]
}

export type TVoteProposal = {
  proposalId: number
  decision: boolean
}
