import { Box, Button, Flex, Heading, Text, useColorModeValue } from '@chakra-ui/react'
import { IntelligenceCard } from '../components'
import { IntelProps } from '../components/IntelligenceCard'

const IntelList: IntelProps[] = [
  {
    title: 'Linguistic',
    description:
      'This is the ability to use language effectively, both verbally and in writing. Ak3s with linguistic intelligence are skilled in understanding and using language for expression, communication and persuasion.',
    url: 'https://tophat.com/glossary/m/multiple-intelligences/',
    image: 'https://ipfs.io/ipfs/bafybeibb6zxy73iw4ldumfk7heegigni4abo66eq7e46z6hw4b5nw3ff3q/1_LingusticIntel.png',
    count: 1,
  },
  {
    title: 'Logical',
    description:
      'This is the ability to reason logically, think critically, and solve problems using numbers and abstract concepts. Ak3s with this type of intelligence are often good at mathematics, science, and other analytical fields.',
    url: 'https://tophat.com/glossary/m/multiple-intelligences/',
    image: 'https://ipfs.io/ipfs/bafybeibb6zxy73iw4ldumfk7heegigni4abo66eq7e46z6hw4b5nw3ff3q/2_LogicalIntel.png',
    count: 2,
  },
  {
    title: 'Spatial',
    description:
      'This is the ability to think in three dimensions and visualize objects and spaces in the mind. Ak3s with spatial intelligence are often skilled in architecture, engineering, and other visually-oriented professions.',
    url: 'https://tophat.com/glossary/m/multiple-intelligences/',
    image: 'https://ipfs.io/ipfs/bafybeibb6zxy73iw4ldumfk7heegigni4abo66eq7e46z6hw4b5nw3ff3q/3_Spatial_Intel.png',
    count: 3,
  },
  {
    title: 'Kinesthetic',
    description:
      "This is the ability to control and coordinate one's movements and manipulate objects with precision. Ak3s with this type of intelligence are often skilled in sports, dance, acting, and other physical activities.",
    url: 'https://tophat.com/glossary/m/multiple-intelligences/',
    image: 'https://ipfs.io/ipfs/bafybeibb6zxy73iw4ldumfk7heegigni4abo66eq7e46z6hw4b5nw3ff3q/4_Kinesthetic_Intel.png',
    count: 4,
  },
  {
    title: 'Musical',
    description:
      ' This is the ability to understand, create, and appreciate music. Ak3s with musical intelligence are often skilled in playing instruments, singing, composing, and producing music.',
    url: 'https://tophat.com/glossary/m/multiple-intelligences/',
    image: 'https://ipfs.io/ipfs/bafybeibb6zxy73iw4ldumfk7heegigni4abo66eq7e46z6hw4b5nw3ff3q/5_Musical_Intel.png',
    count: 5,
  },
  {
    title: 'Interpersonal',
    description:
      "This is the ability to understand and relate to other people's emotions, motivations, and behaviors. Ak3s with this type of intelligence are often skilled in counseling, teaching, leadership, and others.",
    url: 'https://tophat.com/glossary/m/multiple-intelligences/',
    image: 'https://ipfs.io/ipfs/bafybeibb6zxy73iw4ldumfk7heegigni4abo66eq7e46z6hw4b5nw3ff3q/6_Interpersonal_Intel.png',
    count: 6,
  },
  {
    title: 'Intrapersonal',
    description:
      "This is the ability to understand oneself, including one's own emotions, motivations, and goals. Ak3s with this type of intelligence are often introspective, reflective, and self-aware.",
    url: 'https://tophat.com/glossary/m/multiple-intelligences/',
    image: 'https://ipfs.io/ipfs/bafybeibb6zxy73iw4ldumfk7heegigni4abo66eq7e46z6hw4b5nw3ff3q/7_Interpersonal_Intel.png',
    count: 7,
  },
  {
    title: 'Naturalistic',
    description:
      'This is the ability to understand and appreciate the natural world, including plants and animals. Ak3s with this type of intelligence are often skilled in ecology, farming, and other environmentally-oriented fields.',
    url: 'https://tophat.com/glossary/m/multiple-intelligences/',
    image: 'https://ipfs.io/ipfs/bafybeibb6zxy73iw4ldumfk7heegigni4abo66eq7e46z6hw4b5nw3ff3q/8_Naturalistic_Intel.png',
    count: 8,
  },
]
export default function MultipleIntelligence() {
  return (
    <Box bg={useColorModeValue('gray.50', 'gray.800')} color={useColorModeValue('gray.800', 'gray.50')} minH="85vh">
      <Flex pb={12} pt={20} direction="column" justifyContent="space-evenly" alignItems="center">
        <Heading
          textAlign={'center'}
          mb={6}
          px={{ base: '12px', md: '4px' }}
          color={'primary.500'}
          className={'btn-shine'}
        >
          Multiple Intelligence Theory
        </Heading>
        <Text
          fontSize={{ base: 'xs', md: 'xl' }}
          my={6}
          px={{ base: '24px', md: '4px' }}
          textAlign={'justify'}
          maxW={{ base: 'lg', md: '6xl' }}
        >
          The Theory of Multiple Intelligences (MI) is a theory of intelligence that proposes that there are eight
          different intelligences, each of which is relatively independent of the others. It challenges the traditional
          view of intelligence as a single, general ability that can be measured by IQ tests. It has been influential in
          education, as it has led to a more diversified approach to teaching and learning. It has also been used in the
          workplace to help people identify their strengths and weaknesses, and to find jobs that are a good fit for
          their abilities.
        </Text>
        {IntelList.map((intel) => (
          <IntelligenceCard key={intel.title} {...intel} />
        ))}
        <Button
          as={'a'}
          href={'https://www.multipleintelligencesoasis.org/'}
          target={'_blank'}
          rounded={'full'}
          size={'lg'}
          fontWeight={'normal'}
          py={7}
          px={14}
          mt={'20px'}
          bg={'primary.500'}
          color={useColorModeValue('white', 'black')}
          _hover={{ bg: useColorModeValue('black', 'white') }}
        >
          Learn More
        </Button>
      </Flex>
    </Box>
  )
}
