import { Box, Flex, useColorModeValue } from '@chakra-ui/react'
import { CTA, Hero } from '../components'

export default function Academix() {
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.800')}
      color={useColorModeValue('gray.800', 'gray.50')}
      minH="85vh"
    >
      <Flex py={12} direction="column" justifyContent="space-evenly" alignItems="center">
        <Hero />
        
      </Flex>
    </Box>
  )
}
