import {
  Button,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  HStack,
  Box,
  chakra,
  useRadio,
  useRadioGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
  useToast,
  Link,
  Stat,
  StatHelpText,
  StatLabel,
  Spinner,
  Select,
  Input,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { getNFTsByOwner } from '../utils/getNftByOwner'
import {
  useAccount,
  useContractRead,
  useContractReads,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi'
import noImage from '../assets/images/noImage.png'
// import mint from '../assets/images/mint.svg'
import nftImage from '../assets/images/240.png'
import mint from '../assets/images/whatsappp.svg'
import networkMapping from '../assets/constants/network-mapping.json'
import HGManager_ABI from '../assets/constants/HeadgearManager.json'
import Ak3demix_ABI from '../assets/constants/Academix.json'
import whitelist from '../assets/constants/whitelist.json'
import { ethers } from 'ethers'
// import { DAOInfo } from '../components'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { decodeMembershipRequest } from '../utils/basicUtils'
import { MembershipRequest } from '../types/MembershipRequst.type'

type MemberRequestType = {
  tokenId: number
  proposalId: number
  subDao: string
  escrowFunds: string
}

type nftInfo = {
  name: string
  tokenId: string
  imageUrl: string
  traits: string
  isDaoMember: boolean
}

const OPENSEA_TESTNET = 'https://opensea.io/assets/avalanche/'
// const OPENSEA_MAINNET = 'https://opensea.io/assets/avalanche/'
const SNOWTRACE_TESTNET = 'https://snowtrace.io/tx/'
// const SNOWTRACE_MAINNET = 'https://snowtrace.io/tx/'

export default function DaoHome() {
  const { address, status } = useAccount()
  const toast = useToast()
  const [selectedToken, setSelectedToken] = useState<Number | null>()
  const [allNFTs, setAllNFTs] = useState<nftInfo[]>([] as nftInfo[])
  const [activeMemRequest, setActiveMemRequest] = useState<MemberRequestType | null>()
  const [dao, setDao] = useState('')
  const [baseMintPrice, setBaseMintPrice] = useState('')
  const [mintedNftUrl, setMintedNftUrl] = useState('')
  const [tokenCount, setTokenCount] = useState(1)
  const [mintPrice, setMintPrice] = useState('0')
  const [isWhitelisted, setIsWhitelisted] = useState(false)
  useEffect(() => {
    ;(async () => {
      await getNfts()
    })()
  }, [status])

  useEffect(() => {
    const factor = whitelist.address.includes(address as string) ? 0.5 : 1
    setIsWhitelisted(factor !== 1)
    let price = tokenCount * Number(baseMintPrice) * factor || 0
    console.log(price.toFixed(2))
    setMintPrice(price.toFixed(2).toString())
  }, [tokenCount, baseMintPrice, isWhitelisted])

  async function getNfts() {
    const response = await getNFTsByOwner(address?.toString() || '', networkMapping['43114'].Ak3demix)
    let nfts = response.result.assets.map((tkn: any) => {
      return {
        name: tkn['name'],
        tokenId: tkn['tokenId'],
        imageUrl: tkn['imageUrl'],
        traits: tkn['traits'],
        isDaoMember: false,
      }
    })
    setAllNFTs(nfts)
  }

  const handleChange = (value: any) => {
    setSelectedToken(value)
    console.log(value)
  }

  const { value, getRadioProps, getRootProps } = useRadioGroup({
    onChange: handleChange,
  })

  useContractRead({
    address: networkMapping['43114'].Ak3demix as `0x${string}`,
    abi: Ak3demix_ABI,
    functionName: 'tokenMembershipEncoding',
    args: [1],
    onSuccess(data) {
      console.log('tokenMembership:', data)
    },
  })

  useContractReads({
    contracts: allNFTs.map((nft) => ({
      address: networkMapping['43114'].Ak3demix as `0x${string}`,
      abi: Ak3demix_ABI,
      functionName: 'tokenMembershipEncoding',
      args: [nft.tokenId],
    })),
    enabled: allNFTs.length > 0,
    onSuccess(data) {
      // console.log(data)
      let updateNfts = allNFTs.map((nft, index) => {
        return { ...nft, isDaoMember: !!data[index] }
      })
      setAllNFTs(updateNfts)
    },
  })

  useContractRead({
    address: networkMapping['43114'].HeadgearManager as `0x${string}`,
    abi: HGManager_ABI,
    functionName: 'activeMembershipRequestEncodings',
    args: [address],
    onSettled(data: any, error) {
      if (!error) {
        const memRequest: MembershipRequest = decodeMembershipRequest(data)
        setActiveMemRequest({
          tokenId: Number(memRequest.tokenId),
          proposalId: Number(memRequest.proposalId),
          subDao: memRequest.subDao,
          escrowFunds: memRequest.escrowedAmount,
        })
      } else {
        setActiveMemRequest(null)
      }
    },
  })

  useContractRead({
    address: networkMapping['43114'].Ak3demix as `0x${string}`,
    abi: Ak3demix_ABI,
    functionName: 'baseNftMintPrice',
    onSuccess(data: any) {
      console.log('Formated Value', ethers.utils.formatEther(data.toString()))
      setBaseMintPrice(ethers.utils.formatEther(data.toString()))
    },
  })

  const { config: mintConfig, error: mintError } = usePrepareContractWrite({
    address: networkMapping['43114'].Ak3demix as `0x${string}`,
    abi: Ak3demix_ABI,
    functionName: 'mint',
    args: [tokenCount.toString()],
    overrides: {
      value: ethers.utils.parseEther(mintPrice),
    },
    enabled: true,
  })

  const { data: mintData, write: mintAk3demix } = useContractWrite(mintConfig)

  const { isLoading: isMinting } = useWaitForTransaction({
    hash: mintData?.hash,
    onSuccess(data) {
      // setReveal(true)
      console.log('Mint Trx: ', data)
      const tokenId = Number(ethers.utils.formatUnits(data.logs[0].topics[3], 0))
      setMintedNftUrl(`${OPENSEA_TESTNET}${networkMapping['43114'].Ak3demix}/${tokenId}`)
      toast({
        position: 'top-right',
        duration: 10000,
        render: () => (
          <Stat bgColor={'primary.50'} py={'3'} px={'8'} borderRadius={'lg'}>
            <StatLabel>NFT Minted Successfully</StatLabel>
            <StatHelpText>
              {data && (
                <Link href={`${SNOWTRACE_TESTNET}${data?.transactionHash}`} target="_blank">
                  View Transaction
                </Link>
              )}
            </StatHelpText>
          </Stat>
        ),
      })
    },
  })

  function CustomRadio(props: any) {
    const { image, tokenId, ...radioProps } = props
    const { state, getInputProps, getRadioProps, htmlProps, getLabelProps } = useRadio(radioProps)

    return (
      <chakra.label {...htmlProps} cursor={state.isDisabled ? 'not-allowed' : 'pointer'}>
        <input {...getInputProps({})} hidden />
        <Box
          {...getRadioProps()}
          bg={state.isDisabled ? 'gray.300' : state.isChecked ? 'primary.500' : 'transparent'}
          p={1}
          rounded={'lg'}
        >
          <Image src={image || noImage} {...getLabelProps()} w={'220px'} />
          <Text>#{tokenId}</Text>
        </Box>
      </chakra.label>
    )
  }

  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.800')}
      color={'gray.800'}
      minH="85vh"
      bgImage={mint}
      bgPosition={'center'}
      bgRepeat={'no-repeat'}
    >
      {status === 'connected' && (
        <Flex py={12} direction="column" justifyContent="space-evenly" alignItems="center">
          {/* <Tabs size={'lg'} variant="soft-rounded" defaultIndex={0} align="center" pt={5}>
            <TabList mb="1em">
              <Tab
                whiteSpace={'nowrap'}
                border={'1px solid #48d7d4'}
                _selected={{
                  boxShadow: '3px 3px #48d7d4',
                }}
                me={'12px'}
              >
                Mint Ak3demix
              </Tab>
              <Tab
                whiteSpace={'nowrap'}
                border={'1px solid #48d7d4'}
                _selected={{
                  boxShadow: '3px 3px #48d7d4',
                }}
                me={'12px'}
              >
                Request Membership
              </Tab>
              <Tab
                whiteSpace={'nowrap'}
                border={'1px solid #48d7d4'}
                _selected={{
                  boxShadow: '3px 3px #48d7d4',
                }}
                me={'12px'}
              >
                Info
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Stack
                  spacing={4}
                  w={'full'}
                  rounded={'xl'}
                  border={'1px solid #02c6c9'}
                  boxShadow={'5px 5px #02c6c9'}
                  p={6}
                  align={'center'}
                  mt={'20px'}
                  bgColor={'white'}
                >
                  <Box p={'md'} m={'sm'}>
                    <Heading>Ak3demix Mint</Heading>
                    <Image src={noImage} w={'320px'} my={'12px'} borderRadius={'8px'} />
                    <Text fontSize={'3xl'} fontWeight={'800'} lineHeight={'1.5'} mb={'10px'}>
                      {mintPrice} Avax
                    </Text>
                    <HStack alignItems={'center'} justify={'center'}>
                      {isMinting ? (
                        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
                      ) : (
                        <>
                          <Input
                            type={'number'}
                            w={'200px'}
                            value={tokenCount}
                            onChange={(e) => setTokenCount(Number(e.target.value))}
                          ></Input>

                          <Button
                            onClick={mintAk3demix}
                            isDisabled={!!mintError}
                            bg="#0AC5A8"
                            color="white"
                            _hover={{ bg: '#0AC5A8' }}
                            _dark={{ color: 'gray.50' }}
                          >
                            Mint Now
                          </Button>
                        </>
                      )}
                    </HStack>
                    {!mintedNftUrl && (
                      <Stack>
                        <Link href={mintedNftUrl} py={6} textDecoration={'underline'}>
                          View NFT on Opensea
                        </Link>
                      </Stack>
                    )}
                  </Box>
                </Stack>
              </TabPanel>
            </TabPanels>
          </Tabs> */}
          <Stack
            spacing={4}
            rounded={'xl'}
            border={'1px solid #02c6c9'}
            boxShadow={'5px 5px #02c6c9'}
            p={6}
            align={'center'}
            mt={'20px'}
            bgColor={'white'}
          >
            <Box p={'md'} m={'sm'}>
              <Heading>Ak3demix Mint</Heading>
              <Image src={nftImage} w={'320px'} my={'12px'} borderRadius={'8px'} />
              <Text fontSize={'3xl'} fontWeight={'800'} lineHeight={'1.5'} mb={'10px'}>
                {mintPrice} Avax
              </Text>
              {isWhitelisted && (
                <Text fontSize={'lg'} mb={'10px'} bgColor={'blue.200'}>
                  Whitelist Discount Applied!
                </Text>
              )}

              <HStack alignItems={'center'} justify={'center'}>
                {isMinting ? (
                  <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
                ) : (
                  <>
                    <Input
                      type={'number'}
                      w={'200px'}
                      value={tokenCount}
                      onChange={(e) => setTokenCount(Number(e.target.value))}
                    ></Input>

                    <Button
                      onClick={mintAk3demix}
                      isDisabled={!!mintError}
                      bg="#0AC5A8"
                      color="white"
                      _hover={{ bg: '#0AC5A8' }}
                      _dark={{ color: 'gray.50' }}
                    >
                      Mint Now
                    </Button>
                  </>
                )}
              </HStack>
              {/* <p>{JSON.stringify(mintError)}</p> */}
              {mintedNftUrl && (
                <Stack>
                  <Link href={mintedNftUrl} py={6} textDecoration={'underline'}>
                    {' '}
                    View NFT on Opensea
                  </Link>
                </Stack>
              )}
            </Box>
          </Stack>
        </Flex>
      )}
      {status === 'connecting' && (
        <Flex py={12} direction="column" justifyContent="space-evenly" alignItems="center">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="primary.500" size="xl" />
        </Flex>
      )}
      {status === 'disconnected' && (
        <Flex py={12} direction="column" justifyContent="space-evenly" alignItems="center">
          <ConnectButton label="Connect Wallet" />
        </Flex>
      )}
    </Box>
  )
}
