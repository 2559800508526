import { useAccount, useContractReads } from 'wagmi'
import networkMapping from '../assets/constants/network-mapping.json'
import subdao_ABI from '../assets/constants/SubDAO.json'
import { Stack, Heading, Box, Text, HStack, Button } from '@chakra-ui/react'
import { useState } from 'react'
import { ethers } from 'ethers'

const neophyteContract = {
  address: networkMapping['43113'].NeophyhteDAO as `0x${string}`,
  abi: subdao_ABI,
}
const scholarContract = {
  address: networkMapping['43113'].ScholarDAO as `0x${string}`,
  abi: subdao_ABI,
}

const hoodContract = {
  address: networkMapping['43113'].HoodDAO as `0x${string}`,
  abi: subdao_ABI,
}
const royalContract = {
  address: networkMapping['43113'].RoyalDAO as `0x${string}`,
  abi: subdao_ABI,
}

/**
 *  QUORUM
 *  VOTING_PERIOD
 *  dao
 *  name
 *  proposalCount
 *  symbol
 *  totalSupply
 *  votingMemberCount
 */

type daoInfo = {
  symbol: string
  votingPeriod: number
  memberCount: number
  proposalCount: number
  isMember: boolean
  url: string
}

const DAOInfo = () => {
  const { address } = useAccount()
  const [daoInfoList, setDaoInfoList] = useState<daoInfo[]>([] as daoInfo[])
  useContractReads({
    contracts: [
      {
        ...neophyteContract,
        functionName: 'symbol',
      },
      {
        ...neophyteContract,
        functionName: 'VOTING_PERIOD',
      },
      {
        ...neophyteContract,
        functionName: 'votingMemberCount',
      },
      {
        ...neophyteContract,
        functionName: 'proposalCount',
      },
      {
        ...scholarContract,
        functionName: 'symbol',
      },
      {
        ...scholarContract,
        functionName: 'VOTING_PERIOD',
      },
      {
        ...scholarContract,
        functionName: 'votingMemberCount',
      },
      {
        ...scholarContract,
        functionName: 'proposalCount',
      },

      {
        ...hoodContract,
        functionName: 'symbol',
      },
      {
        ...hoodContract,
        functionName: 'VOTING_PERIOD',
      },
      {
        ...hoodContract,
        functionName: 'votingMemberCount',
      },
      {
        ...hoodContract,
        functionName: 'proposalCount',
      },
      {
        ...royalContract,
        functionName: 'symbol',
      },
      {
        ...royalContract,
        functionName: 'VOTING_PERIOD',
      },
      {
        ...royalContract,
        functionName: 'votingMemberCount',
      },
      {
        ...royalContract,
        functionName: 'proposalCount',
      },
      {
        ...neophyteContract,
        functionName: 'balanceOf',
        args: [address],
      },
      {
        ...scholarContract,
        functionName: 'balanceOf',
        args: [address],
      },
      {
        ...hoodContract,
        functionName: 'balanceOf',
        args: [address],
      },
      {
        ...royalContract,
        functionName: 'balanceOf',
        args: [address],
      },
    ],
    staleTime: 2_000,
    onSuccess(data: any) {
      console.log('Success', data)
      let neoInfo: daoInfo = {
        symbol: data[0].toString(),
        votingPeriod: Number(ethers.utils.formatUnits(data[1], 0)) / 86400, // conversion to days
        memberCount: Number(ethers.utils.formatUnits(data[2], 0)),
        proposalCount: Number(ethers.utils.formatUnits(data[3], 0)),
        isMember: Number(ethers.utils.formatUnits(data[16], 0)) > 0,
        url: '/neo-dao',
      }
      let scholarInfo: daoInfo = {
        symbol: data[4].toString(),
        votingPeriod: Number(ethers.utils.formatUnits(data[5], 0)) / 86400, // conversion to days
        memberCount: Number(ethers.utils.formatUnits(data[6], 0)),
        proposalCount: Number(ethers.utils.formatUnits(data[7], 0)),
        isMember: Number(ethers.utils.formatUnits(data[17], 0)) > 0,
        url: '/scholar-dao',
      }
      let hoodInfo: daoInfo = {
        symbol: data[8].toString(),
        votingPeriod: Number(ethers.utils.formatUnits(data[9], 0)) / 86400, // conversion to days
        memberCount: Number(ethers.utils.formatUnits(data[10], 0)),
        proposalCount: Number(ethers.utils.formatUnits(data[11], 0)),
        isMember: Number(ethers.utils.formatUnits(data[18], 0)) > 0,
        url: '/hood-dao',
      }
      let royalInfo: daoInfo = {
        symbol: data[12].toString(),
        votingPeriod: Number(ethers.utils.formatUnits(data[13], 0)) / 86400, // conversion to days
        memberCount: Number(ethers.utils.formatUnits(data[14], 0)),
        proposalCount: Number(ethers.utils.formatUnits(data[15], 0)),
        isMember: Number(ethers.utils.formatUnits(data[19], 0)) > 0,
        url: '/royal-dao',
      }
      setDaoInfoList([neoInfo, scholarInfo, hoodInfo, royalInfo])
    },
  })
  return (
    <Stack
      spacing={4}
      w={'full'}
      p={6}
      rounded={'xl'}
      border={'1px solid #02c6c9'}
      boxShadow={'5px 5px #02c6c9'}
      align={'center'}
      mt={'20px'}
    >
      <Heading my={6}>DAO Info</Heading>
      <HStack>
        {daoInfoList.length > 0 &&
          daoInfoList.map((dao) => {
            return (
              <Box key={dao.symbol} bgColor={'gray.200'} p={4} alignContent={'flex-start'}>
                <Heading mb={2} fontStyle={'oblique'}>{`[${dao.symbol}]`}</Heading>
                <Text>Voting Period: {dao.votingPeriod} days</Text>
                <Text>Members: {dao.memberCount} Addresses</Text>
                <Text>Proposal Count: {dao.proposalCount}</Text>
                <Button isDisabled={!dao.isMember} as={'a'} href={dao.url}>
                  Enter DAO
                </Button>
              </Box>
            )
          })}
      </HStack>
    </Stack>
  )
}
export default DAOInfo
