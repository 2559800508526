import { SimpleGrid, Flex, Heading, Spacer, Icon, Text, Link, Image, Box } from '@chakra-ui/react'
import { FaTwitter, FaLinkedinIn, FaGlobe, FaInstagram } from 'react-icons/fa'

export type TeamMember = {
  name: string
  position: string
  bio: string
  href: string
  social: 'twitter' | 'linkedin' | 'web' | 'insta'
  photo: string
}

type TeamProps = {
  team: TeamMember[]
}

const Team = ({ team }: TeamProps) => {
  return (
    <SimpleGrid columns={[1, 1, 3]} spacing={50} px={{ base: 5, md: 20 }} py={20}>
      {team.map((member) => (
        <Flex
          key={member.name}
          borderRadius="lg"
          _hover={{ boxShadow: '5px 5px gray' }}
          textAlign="center"
          direction="column"
          justifyContent="top"
          alignItems="top"
          marginTop={3}
          bg={'gray.100'}
        >
          <Flex position="relative" direction="column" alignItems="center" justify={'start'} marginTop={'-80px'}>
            <Image
              src={member.photo}
              alt={member.name}
              borderRadius="50%"
              boxSize={{ base: 100, lg: 150 }}
              align="center"
              fit="contain"
              marginTop={5}
            />
          </Flex>
          <Box p={{ base: 6, md: 6 }} bg={'transparent'}>
            <Heading as="h2" size="md" mb={{ base: 0, lg: 2 }}>
              {member.name}
            </Heading>
            <Text fontWeight={600} mb={{ base: 0, lg: 2 }} color={'primary.500'}>
              {member.position}
            </Text>
            <Text fontSize={{ base: 'xs', md: 'md' }}>{member.bio}</Text>
          </Box>
          {member.href !== '/#' && (
            <>
              <Spacer />
              <Link href={member.href} target="_blank">
                {member.social === 'twitter' && <Icon as={FaTwitter} height={8} width={8} color={'primary.500'} />}
                {member.social === 'linkedin' && <Icon as={FaLinkedinIn} height={8} width={8} color={'primary.500'} />}
                {member.social === 'web' && <Icon as={FaGlobe} height={8} width={8} color={'primary.500'} />}
                {member.social === 'insta' && <Icon as={FaInstagram} height={8} width={8} color={'primary.500'} />}
              </Link>
            </>
          )}
        </Flex>
      ))}
    </SimpleGrid>
  )
}
export default Team
