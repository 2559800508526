import {
  Box,
  Container,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
  Button,
  Image,
  HStack,
  Heading,
  VStack,
} from '@chakra-ui/react'
import { FaDiscord, FaMediumM, FaTwitter } from 'react-icons/fa'
import { ReactNode } from 'react'
import logo from '../assets/images/logo.png'

export const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode
  label: string
  href: string
}) => {
  return (
    <Button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={12}
      h={12}
      cursor={'pointer'}
      as={'a'}
      href={href}
      target={'_blank'}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </Button>
  )
}

export default function SmallWithLogoLeft() {
  return (
    <Box
      // bg={useColorModeValue('gray.100', 'gray.900')}
      bg={'gray.100'}
      color={'black'}
      // color={useColorModeValue('gray.700', 'gray.200')}
    >
      <Container
        as={Stack}
        maxW={'7xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
      >
        <HStack>
          <Image src={logo} alt={'ak3demix_logo'} width={'70px'} />
          <Heading>AK3DEMIX</Heading>
        </HStack>
        <VStack>
          <Text>© 2023 Ak3demix. All rights reserved</Text>
          <Text as={'a'} href={'https://subtlew3.netlify.app/'} target={'_blank'} color={'#0ac5a8'}>
            Developed By SubtleWeb3 💖
          </Text>
        </VStack>
        <Stack direction={'row'} spacing={6}>
          <SocialButton label={'Medium'} href={'https://medium.com/@ak3demia'}>
            <FaMediumM />
          </SocialButton>
          <SocialButton label={'Twitter'} href={'https://twitter.com/ak3demix'}>
            <FaTwitter />
          </SocialButton>
          <SocialButton label={'Discord'} href={'https://discord.gg/Hsz25xsDbx'}>
            <FaDiscord />
          </SocialButton>
        </Stack>
      </Container>
    </Box>
  )
}
