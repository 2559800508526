import { useEffect } from 'react'
import './RoadMap.css'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Heading } from '@chakra-ui/layout'
import { useColorModeValue, Box, Flex, Text } from '@chakra-ui/react'

export default function RoadMap() {
  const items = [
    {
      title: 'Seeds of Knowledge',
      description:
        'Seeds of knowledge gave early access to early supporters of the project, and ensured their inclusion in all future project endeavors.',
      date: 'Phase 0',
    },
    {
      title: ' NF_Seed to Ak3demix and launch a Free Mint',
      description: 'A free mint that highlights the MI theory and comes with its own unique utility.',
      date: 'Phase 1',
    },
    {
      title: 'Arrival',
      description:
        'The Ak3s will arrive in 8 waves of 1,111 and each minting round will increase in price. The first 1,111 Ak3s will arrive to Avalanche and will receive access to their meeting rooms (sub-DAOs) to discuss and create their own history and vote on key proposals from the Supreme Council.',
      date: 'Phase 2',
    },
    {
      title: 'Post-Mint Expansion of the DAO',
      description:
        'The Aks with each round of mints there will be a specific deliverables that the community can vote on.', //While a bare bone DAO and subDAOs will be available at completion of mint, the Ak3s always seek perfection and will immediately get to work on expanding their board rooms to prepare for the arrival of their bredren.
      date: 'Phase 3',
    },
    {
      title: 'Expansion beyond the DAO',
      description:
        'We are looking to connect with educational foundations and institutions around the world and will be taking a philanthropic approach to it. More to follow. ',
      date: 'Phase 4',
    },
    // {
    //   title: 'Launch NF_Seed Game',
    //   description:
    //     'A simple puzzle game with NFT rewards to only the fastest and smartest of the Ak3s.',
    //   date: 'Phase 5',
    // },
    // {
    //   title: 'Educational Games',
    //   description: 'Live playable education games. ',
    //   date: 'Phase 6',
    // },
    {
      title: 'A****** *********e',
      description:
        '******* ****** ************** **** ** *** ******* ****** ****** ******** ****** ***** ****** *** *** ***** *********** **** ****  ** ******  ********* ********  ******',
      date: 'Phase 5',
    },
  ]

  const animateFromTo = (elem: any, direction: any) => {
    const offset = 1000
    let x = 0
    let y = direction * offset

    direction = direction | 1

    if (elem.classList.contains('slide_from_left')) {
      x = -offset
      y = 0
    } else if (elem.classList.contains('slide_from_right')) {
      x = offset
      y = 0
    }

    gsap.fromTo(
      elem,
      { x: x, y: y, autoAlpha: 0 },
      {
        duration: 2.5,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: 'expo',
        overwrite: 'auto',
      }
    )
  }

  const hide = (elem: any) => {
    gsap.set(elem, { autoAlpha: 0 })
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    gsap.utils.toArray('.animate').forEach(function (elem: any) {
      hide(elem)

      ScrollTrigger.create({
        trigger: elem,
        onEnter: function () {
          animateFromTo(elem, 1)
        },
        onEnterBack: function () {
          animateFromTo(elem, -1)
        },
        onLeave: function () {
          hide(elem)
        },
      })
    })
  }, [])

  return (
    <Box bg={useColorModeValue('gray.50', 'gray.800')} color={useColorModeValue('gray.800', 'gray.50')} minH="85vh">
      <Flex pb={12} pt={20} direction="column" justifyContent="space-evenly" alignItems="center">
        <Heading textAlign={'center'} mb={6} color={'primary.500'} className={'btn-shine'}>
          RoadMap
        </Heading>
        <Text fontSize={{ base: 'md', md: 'xl' }} mt={3} mb={12} textAlign={'justify'} maxW={{ base: 'lg', md: '4xl' }}>
          Ak3demix team has been building intelligent projects that showcase over the last few years to share the idea
          of multiple intelligences and its benefits to everyone.
        </Text>
        <div className="timeline">
          <ul>
            {items.map((te: any, idx: any) => {
              return (
                <li key={`${te.title}_${te.date}`}>
                  <div className="content">
                    <Heading
                      fontSize={'1.5rem'}
                      color={'primary.500'}
                      className={`animate ${idx % 2 === 0 ? 'slide_from_left' : 'slide_from_right'}`}
                    >
                      {te.title}
                    </Heading>
                    <p className={`animate ${idx % 2 === 0 ? 'slide_from_left' : 'slide_from_right'}`}>
                      {te.description}
                    </p>
                  </div>
                  <div className={`time animate ${idx % 2 === 0 ? 'slide_from_right' : 'slide_from_left'}`}>
                    <Heading fontSize={'1rem'}>{te.date}</Heading>
                  </div>
                </li>
              )
            })}
            {/* <div style={{ clear: 'both' }}></div> */}
          </ul>
        </div>
      </Flex>
    </Box>
  )
}
