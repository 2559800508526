import { Container, Stack, Flex, Box, Heading, Text, Image } from '@chakra-ui/react'
import { grid, ancients, akies } from '../assets/images'
import CharacterCard from './CharacterCard'
import { CTA } from './CTA'

export function Hero() {
  return (
    <Container maxW={'7xl'}>
      <Stack
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 8, md: 28 }}
        direction={{ base: 'column', md: 'row' }}
      >
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}
            textAlign={{ base: 'center', md: 'left' }}
          >
            <Text as={'span'} position={'relative'}>
              WHAT IS
            </Text>
            <br />
            <Text as={'span'} color={'primary.500'}>
              AK3DEMIX?
            </Text>
          </Heading>
          <Text color={'gray.500'} textAlign={{ base: 'center', md: 'left' }}>
            AK3DEMIX is a generative art collection of 8,888 NFTs, with each trait hand drawn by our artist.
          </Text>
        </Stack>
        <Flex flex={1} justify={'center'} align={'center'} position={'relative'} w={'full'}>
          <Box
            position={'relative'}
            rounded={'2xl'}
            boxShadow={'2xl'}
            width={{ base: 'md', md: 'full' }}
            overflow={'hidden'}
          >
            <Image alt={'Hero Image'} fit={'cover'} align={'center'} w={'100%'} h={'100%'} src={grid} />
          </Box>
        </Flex>
      </Stack>
      <Stack
        align={'center'}
        spacing={{ base: 6, md: 10 }}
        py={{ base: 10, md: 28 }}
        direction={{ base: 'column-reverse', md: 'row' }}
      >
        <Flex flex={1} justify={'center'} align={'center'} position={'relative'} w={'full'}>
          <Box
            position={'relative'}
            rounded={'2xl'}
            boxShadow={'2xl'}
            width={{ base: 'md', md: 'full' }}
            overflow={'hidden'}
          >
            <Image alt={'Hero Image'} fit={'contain'} align={'center'} w={'100%'} h={'100%'} src={akies} />
          </Box>
        </Flex>
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}
            textAlign={{ base: 'center', md: 'left' }}
          >
            <Text as={'span'} position={'relative'}>
              THE AK3DEMIX A.K.A “AK3S
            </Text>
            <br />
            <Text as={'span'} color={'secondary.500'} fontStyle={'oblique'} fontSize={{ base: '2xl', lg: '4xl' }}>
              [AH-KEYS]
            </Text>
          </Heading>
          <Text textAlign={{ base: 'center', md: 'left' }}>
            AK3S are an advanced peace-loving alien race who's ancestors were able to unlock the powers of the Original
            Seed which gave them 8 Ultimate Intelligences. A well-segmented highly intelligent society they have
            complete control over their story and play their part in its creation.
          </Text>
        </Stack>
      </Stack>
      <Stack
        align={'center'}
        spacing={{ base: 6, md: 10 }}
        py={{ base: 10, md: 28 }}
        direction={{ base: 'column', md: 'row' }}
      >
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}
            textAlign={{ base: 'center', md: 'left' }}
          >
            <Text as={'span'} position={'relative'}>
              THE ANCIENTS A.K.A “CARN3UM ANCI3NTUM
            </Text>
            <br />
            <Text as={'span'} color={'secondary.500'} fontStyle={'oblique'} fontSize={{ base: '2xl', lg: '4xl' }}>
              [CARN-E-UM AN-CHN-TUM]
            </Text>
          </Heading>
          <Text textAlign={{ base: 'center', md: 'left' }}>
            A nod to the skull collections and collectors on Avalanche, they are the fossils of the Wise Ones that were
            able to unlock the secrets of the Original Seed. They are rumored to contain within them the powers of
            Ultimate Intelligence.
          </Text>
        </Stack>
        <Flex flex={1} justify={'center'} align={'center'} position={'relative'} w={'full'}>
          <Box
            position={'relative'}
            rounded={'2xl'}
            boxShadow={'2xl'}
            width={{ base: 'md', md: 'full' }}
            overflow={'hidden'}
          >
            <Image alt={'Hero Image'} fit={'contain'} align={'center'} w={'100%'} h={'100%'} src={ancients} />
          </Box>
        </Flex>
      </Stack>
      <CharacterCard />
      <Stack
        align={'center'}
        spacing={{ base: 6, md: 10 }}
        py={{ base: 10, md: 28 }}
        direction={{ base: 'column', md: 'row' }}
      >
        <Heading lineHeight={1} fontWeight={600} fontSize={{ base: '3xl', md: '6xl' }}>
          <Text as={'span'} color={'primary.500'}>
            Will you join them on their adventure as they create their story?
          </Text>
        </Heading>
      </Stack>
      <CTA />
    </Container>
  )
}
