import { useColorModeValue } from '@chakra-ui/react'
import { Header, Footer, NeoSubDao, HoodSubDao, RoyalSubDao, ScholarSubDao } from './components'
import { Error404, AboutUs, RoadMap, Academix, MultipleIntelligence, PreviousCollection, DaoHome } from './pages'
import { Route, Routes } from 'react-router-dom'
import { Youtube } from './components/Youtube'

function App() {
  return (
    <div className="App" style={{ backgroundColor: useColorModeValue('gray.50', 'gray.800') }}>
      <Header />
      <div className="MainContent">
        <Routes>
          <Route path="/" element={<Academix />} />
          <Route path="/*" element={<Error404 />} />
          <Route path="/supreme-council" element={<AboutUs />} />
          <Route path="/roadmap" element={<RoadMap />} />
          <Route path="/previous-collections" element={<PreviousCollection />} />
          <Route path="/multiple-intelligence" element={<MultipleIntelligence />} />
          <Route path="/mint" element={<DaoHome />} />
          <Route path="/neo-dao" element={<NeoSubDao />} />
          <Route path="/scholar-dao" element={<ScholarSubDao />} />
          <Route path="/hood-dao" element={<HoodSubDao />} />
          <Route path="/royal-dao" element={<RoyalSubDao />} />
          <Route path="/lore" element={<Youtube />} />
          {/* <Route path="/dao-admin" element={<DaoAdmin />} /> */}
        </Routes>
      </div>
      <Footer />
    </div>
  )
}

export default App
