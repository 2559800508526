import { VStack, Heading, Stack, Flex, Center, useColorModeValue, Image, Text } from '@chakra-ui/react'
import wavelines1 from '../assets/images/wavelines1.svg'
import mintPng from '../assets/images/hero4.png'
import chapter1 from '../assets/images/Chapter_1.png'
import chapter2 from '../assets/images/Chapter_2.png'
import YouTube from 'react-youtube'

export function Youtube() {
  return (
    <VStack
      minH={'55vh'}
      mt={'4rem'}
      direction={{ base: 'column' }}
      align={'center'}
      justify={'center'}
      bgImage={wavelines1}
      bgSize={'cover'}
    >
      <Heading fontSize={{ base: '4xl', md: '6xl' }}>
        <Text as={'span'} color={useColorModeValue('primary.500', 'gray.50')} position={'relative'} fontWeight={'800'}>
          Lore
        </Text>
      </Heading>

      <Heading fontSize={{ base: '3xl', md: '4xl' }}>
        <Text as={'span'} color={useColorModeValue('gray.800', 'gray.50')} position={'relative'} fontWeight={'600'}>
          1. Pursuit of the Ancients
        </Text>
      </Heading>
      {/* <Stack direction={{ base: 'column', md: 'row' }} bgImage={chapter1} padding={'30px 50px'}> */}
      <Stack direction={{ base: 'column', md: 'column' }}>
        <Flex px={8} flex={1}>
          <Image alt={'Hero Image'} objectFit={'cover'} src={chapter1} />
        </Flex>
        <Flex flex={1} align={'center'} justify={'center'}>
          <YouTube videoId="1UcPsKpCeLg" />
        </Flex>
      </Stack>
      <Heading fontSize={{ base: '3xl', md: '4xl' }} py={7}>
        <Text as={'span'} color={useColorModeValue('gray.800', 'gray.50')} position={'relative'} fontWeight={'600'}>
          2. Harvest Hunt: Feast of the First Fruit
        </Text>
      </Heading>
      <Stack direction={{ base: 'column', md: 'column' }}>
        <Flex flex={1} align={'center'} justify={'center'}>
          <YouTube videoId="EyvAnFTdQO8" />
        </Flex>
        <Flex flex={1}>
          <Image alt={'Hero Image'} objectFit={'contain'} src={chapter2} />
        </Flex>
      </Stack>
    </VStack>
  )
}
