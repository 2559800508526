import { Container, SimpleGrid, chakra, Stack, Box, Heading, useColorModeValue, Button } from '@chakra-ui/react'

export const CTA = () => {
  return (
    <Container maxW={'7xl'}>
      <Stack align={'center'} direction={{ base: 'column', md: 'row' }}>
        <SimpleGrid
          maxW="7xl"
          w={{
            md: '3xl',
            lg: '4xl',
          }}
          columns={{
            base: 1,
            lg: 2,
            xl: 3,
          }}
          spacing={4}
          display={{
            lg: 'flex',
          }}
        >
          <Box>
            <Heading
              fontSize={{
                base: '3xl',
                sm: '4xl',
              }}
              fontWeight="extrabold"
              letterSpacing="tight"
              lineHeight="shorter"
              color="gray.900"
              _dark={{
                color: 'gray.100',
              }}
              mb={6}
            >
              <chakra.span display="block">Ready to dive in?</chakra.span>
              <chakra.span display="block" color={useColorModeValue('primary.500', 'gray.50')}>
                Join our Discord Community.
              </chakra.span>
            </Heading>
            <Button
              rounded={'full'}
              size={'lg'}
              fontWeight={'normal'}
              p={7}
              bg={'primary.500'}
              color={useColorModeValue('white', 'black')}
              _hover={{ bg: useColorModeValue('black', 'white') }}
              as={'a'}
              href={'https://discord.gg/Hsz25xsDbx'}
              target="_blank"
            >
              Join Discord
            </Button>
          </Box>
        </SimpleGrid>
      </Stack>
    </Container>
  )
}
