import { ethers } from 'ethers'

export async function getLoreDetails(ipfsHashBytes: string) {
  const ipfsHash = new TextDecoder().decode(ethers.utils.arrayify(ipfsHashBytes))
  const response = await fetch(`https://ipfs.io/ipfs/${ipfsHash}/metadata.json`)
    const responseJson = await response.json()
  const infoObject = {
    description: responseJson.description,
    imageUrl: `https://ipfs.io/ipfs/${responseJson.image.slice(7)}`,
  }
  return infoObject
}
