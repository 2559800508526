import { Box, Flex, Heading, Text, useColorModeValue } from '@chakra-ui/react'
import CollectionCard, { CollectionCardProps } from '../components/CollectionCard'
import mintPng from '../assets/images/mint.png'
import inthemix from '../assets/images/in-the-mix.png'
const PreviousCollection = () => {
  return (
    <Box bg={useColorModeValue('gray.50', 'gray.800')} color={useColorModeValue('gray.800', 'gray.50')} minH="85vh">
      <Flex pb={12} pt={20} direction="column" justifyContent="space-evenly" alignItems="center">
        <Heading textAlign={'center'} mb={6} color={'primary.500'} className={'btn-shine'}>
          Previous Collections
        </Heading>
        <Text fontSize="xl" my={6} textAlign={'center'} maxW={{ base: 'full', md: '4xl' }}>
          Our previous collections have been a banger. Collections sold out in less than 8 minutes.
          <br />
          Check out these collections and own them to get exciting benefits in out upcoming projects.
        </Text>
        {previousCollections.map((collection: CollectionCardProps) => {
          return <CollectionCard key={collection.title} {...collection} />
        })}
      </Flex>
    </Box>
  )
}
export default PreviousCollection

const previousCollections: CollectionCardProps[] = [
  {
    image: inthemix,
    title: 'In The Mix',
    description:
      "A hand-drawn collection of 320 NFTs inspired by Dr. Howard Gardner's theory of multiple intelligences. Each digital artwork represents a unique intelligence, inviting viewers to explore the diversity of human cognition. Through vibrant visuals and symbolic representations, this collection bridges art and education, fostering dialogue and appreciation for our varied abilities. Embark on a captivating journey through 'In the Mix' celebrating the richness of human intelligence and unlocking opportunities for personal growth.",
    collectionUrl: 'https://campfire.exchange/collections/0x1db2086be844cf64ba3c929dac119b05eed1fc00',
    infoUrl: 'https://medium.com/@ak3demia/in-the-mix-b4b28be419a1',
  },
  {
    image: mintPng,
    title: 'Seeds of Knowledge',
    description:
      "Planting seeds of knowledge. 🌱 Intelligence is not singular. Seeds of knowledge is a collection of 1/1 pieces each bringing to life the artist's depiction of a specific class of intelligence. The winner of each auction also gets a Souls of Knowledge SB token that plays a large part in the stages to come.",
    collectionUrl: 'https://campfire.exchange/collections/0x5ac9abdf368aaaa8df9fb729f3c0eed15c98cf29',
  },
]
