export const CHAIN_NAME = process.env.REACT_APP_CHAIN_NAME || ''
export const ANKR_BASE_URL = process.env.REACT_APP_ANKR_BASE_URL || ''

export async function getNFTsByOwner(ownerAddress: string, contractAddress: string) {
  const response = await fetch(ANKR_BASE_URL, {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({
      jsonrpc: '2.0',
      method: 'ankr_getNFTsByOwner',
      params: {
        blockchain: [CHAIN_NAME],
        walletAddress: ownerAddress,
        pageSize: 12,
        pageToken: '',
        filter: [
          {
            [contractAddress]: [],
          },
        ],
      },
      id: 1,
    }),
  })
  return await response.json()
}
