import { Box, Flex, Heading, Text, useColorModeValue } from '@chakra-ui/react'
import { sam, ak, ali, joey, srikanthalva, logo } from '../assets/images'
import Team, { TeamMember } from '../components/Team'

export default function AboutUs() {
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.800')}
      // color={useColorModeValue('gray.800', 'gray.50')}
      color={'gray.800'}
      minH="85vh"
    >
      <Flex pb={12} pt={20} direction="column" justifyContent="space-evenly" alignItems="center">
        <Heading mb={6} color="primary.500" className={'btn-shine'}>
          Council Members
        </Heading>
        <Text fontSize={{ base: 'xs', lg: 'xl' }} mx={6} textAlign={'center'}>
          We are a team of passionate educators and builders working to build the new age educational application.
        </Text>
        <Team team={team} />
      </Flex>
      <Flex pb={12} direction="column" justifyContent="space-evenly" alignItems="center">
        <Heading mb={6} color="primary.500">
          Advisory Board
        </Heading>
        <Text fontSize={{ base: 'xs', lg: 'xl' }} mx={6} textAlign={'center'}>
          We are a team of passionate educators and builders working to build the new age educational application.
        </Text>
        <Team team={advisors} />
      </Flex>
    </Box>
  )
}

const team: TeamMember[] = [
  {
    name: 'Sam Barton',
    position: 'Co-Founder',
    bio: 'Head of the English Department with over 15 years experience in teaching K-12. Holds a Masters degree in curriculum design and assessment..',
    href: '/#',
    social: 'web',
    photo: sam,
  },
  {
    name: 'Abdulla Alakram',
    position: 'Co-Founder',
    bio: 'External assessment coordinator and digital coach with 10 years of experience in education with a focus on early years.',
    href: 'https://linktr.ee/aakram',
    social: 'web',
    photo: ak,
  },
  {
    name: 'Yusuf (Joe) Mahchi',
    position: 'Co-Founder',
    bio: '10+ as a Financial Analyst and Manager with experience in telecom, FMCG, program management and budgeting.',
    href: '/#',
    social: 'web',
    photo: joey,
  },
  {
    name: 'Srikanth Alva',
    position: 'Web3 Developer',
    bio: 'Developer with over 8 years of experience in application development and 3+ years in Web3 building Smart Contracts,Dapps and Mentoring Web3 Enthusiasts.',
    href: 'https://www.linkedin.com/in/srikanthalva/',
    social: 'linkedin',
    photo: srikanthalva,
  },
  // {
  //   name: 'Fran',
  //   position: 'Game Developer | Artist',
  //   bio: 'Graphic designer and Unity game developer.',
  //   href: 'https://herasrevenge.es/',
  //   social: 'web',
  //   photo: img4,
  // },
  {
    name: 'SpaceWhaleRider',
    position: 'Artist',
    bio: 'SpaceWhaleRider is a scientist by trade but has been drawing and creating characters ever since he could hold a pencil. He is self taught and is naturally drawn to colors and bold lines, inspired by nature and urban settings.',
    href: '/#',
    social: 'web',
    photo: ali,
  },
]

const advisors: TeamMember[] = [
  {
    name: 'Richard F. Moroco',
    position: 'Legal',
    bio: 'Focusing on mergers and acquisitions, entity formations, venture capital finance, corporate governance and securities law, and financial restructuring and workouts',
    href: 'https://morocolaw.com/',
    social: 'web',
    photo: logo,
  },
  {
    name: 'Sadeq Ebrahim',
    position: 'Business Development',
    bio: 'With 30+ years in FinTech and Financial Services, he is a strategist with a proven track record in turning vision into reality, setting up new businesses, raising capital, negotiating & structuring multi-billion dollar corp.',
    href: '/#',
    social: 'web',
    photo: logo,
  },
  {
    name: 'Taimour Raouf',
    position: 'Communications',
    bio: 'Head of Communications and Public Relations at the largest Islamic Bank in Bahrain and one of the largest investment groups in the GCC.',
    href: '/#',
    social: 'web',
    photo: logo,
  },
]
