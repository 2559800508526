import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import '@rainbow-me/rainbowkit/styles.css'
import { lightTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { configureChains, createClient, WagmiConfig } from 'wagmi'
import { avalanche, avalancheFuji } from 'wagmi/chains'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import { ChakraProvider, theme as base } from '@chakra-ui/react'
import { connectorsForWallets } from '@rainbow-me/rainbowkit'
import {
  injectedWallet,
  rainbowWallet,
  metaMaskWallet,
  // coinbaseWallet,
  // walletConnectWallet,
  ledgerWallet,
} from '@rainbow-me/rainbowkit/wallets'
import { extendTheme } from '@chakra-ui/react'
import { BrowserRouter } from 'react-router-dom'
import { Global } from '@emotion/react'

const { chains, provider } = configureChains(
  [avalanche],
  [alchemyProvider({ apiKey: 'dHRURMHlGbWZvFYibmuOISLRyd_zW2Vs' }), publicProvider()]
)

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      injectedWallet({ chains }),
      metaMaskWallet({ chains }),
      // coinbaseWallet({ chains, appName: 'All Dapps' }),
    ],
  },
  {
    groupName: 'Others',
    wallets: [rainbowWallet({ chains }), ledgerWallet({ chains })],
  },
])

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
})

const Fonts = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
        font-family: 'Gaoel';
        src: local('Gaoel'), url('./assets/fonts/Gaoel.ttf') format('truetype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      `}
  />
)

const theme = extendTheme({
  colors: {
    primary: {
      50: '#c2fdfe',
      400: '#02c6c9',
      500: '#48d7d4',
      600: '#015f60',
    },
    secondary: {
      500: '#1ab0e9',
    },
    gray: {
      50: '#fdf9fa',
      800: '#252928',
    },
  },
  fonts: {
    // heading: `'Orbitron', sans-serif;`,
    heading: `Gaoel, ${base.fonts?.heading}`,
    body: `'Exo 2', sans-serif;`,
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        modalSize={'compact'}
        theme={lightTheme({
          accentColor: '#48d7d4',
          borderRadius: 'medium',
          overlayBlur: 'small',
        })}
        appInfo={{
          appName: 'Ac3demix Dapp',
        }}
      >
        <ChakraProvider theme={theme}>
          <BrowserRouter>
            <Fonts />
            <App />
          </BrowserRouter>
        </ChakraProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
)
