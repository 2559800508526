import {
  HStack,
  Heading,
  Text,
  Container,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  useColorModeValue,
  Button,
  FormControl,
  FormLabel,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Table,
  Tbody,
  Td,
  Tr,
  Input,
  Collapse,
  useDisclosure,
  TableContainer,
  Progress,
  Stat,
  StatLabel,
  StatNumber,
  Spinner,
  StatHelpText,
  Tag,
  VStack,
} from '@chakra-ui/react'
import {
  useContractReads,
  useBalance,
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi'
import networkMapping from '../assets/constants/network-mapping.json'
import subdao_ABI from '../assets/constants/SubDAO.json'
import hgManager_ABI from '../assets/constants/HeadgearManager.json'
import { ethers } from 'ethers'
import { useEffect, useState } from 'react'
import { shortAddress, timeConverter } from '../utils/basicUtils'
import { TDaoInfo, TProposal, TVoteProposal, ProposalType } from '../types/SubDaoDetails.type'

const daoContract = {
  address: networkMapping['43113'].ScholarDAO as `0x${string}`,
  abi: subdao_ABI,
}

const hgManagerContract = {
  address: networkMapping['43113'].HeadgearManager as `0x${string}`,
  abi: hgManager_ABI,
}

const ScholarSubDao = () => {
  const { isOpen, onToggle } = useDisclosure()
  const { address } = useAccount()
  const [daoInfo, setDaoInfo] = useState<TDaoInfo>({} as TDaoInfo)
  const [userTokens, setUserTokens] = useState('')
  const [proposals, setProposals] = useState<TProposal[]>([] as TProposal[])
  const [voteProposal, setVoteProposal] = useState<TVoteProposal>({} as TVoteProposal)
  const [newMintPrice, setNewMintPrice] = useState<string>('0')

  const { data } = useBalance({
    address: networkMapping['43113'].ScholarDAO as `0x${string}`,
    formatUnits: 'ether',
  })

  useContractReads({
    contracts: [
      {
        ...daoContract,
        functionName: 'symbol',
      },
      {
        ...daoContract,
        functionName: 'LORE',
      },
      {
        ...daoContract,
        functionName: 'QUORUM',
      },
      {
        ...daoContract,
        functionName: 'VOTING_PERIOD',
      },
      {
        ...daoContract,
        functionName: 'votingMemberCount',
      },
      {
        ...daoContract,
        functionName: 'proposalCount',
      },
      {
        ...daoContract,
        functionName: 'balanceOf',
        args: [address],
      },
      {
        ...hgManagerContract,
        functionName: 'subDaoDetails',
        args: [1],
      },
      {
        ...daoContract,
        functionName: 'totalSupply',
      },
    ],
    staleTime: 2_000,
    onSuccess(data: any) {
      console.log('Success', data)
      setDaoInfo({
        Symbol: data[0].toString(),
        Lore: data[1] || 'No Lore Set',
        Quorum: `Min ${Number(ethers.utils.formatUnits(data[2], 0))} %`,
        'Voting Period': `${Number(ethers.utils.formatUnits(data[3], 0)) / 86400} days`, // conversion to days
        'Total Members': Number(ethers.utils.formatUnits(data[4], 0)),
        'Total Proposals': Number(ethers.utils.formatUnits(data[5], 0)),
        'Mint Price': ethers.utils.formatUnits(data[7][1], 'ether') + ' Avax',
        'Total Supply': ethers.utils.formatUnits(data[8], 'ether') + ' Avax',
      })
      setUserTokens(ethers.utils.formatUnits(data[6], 'ether'))
      setNewMintPrice(ethers.utils.formatUnits(data[7][1], 'ether'))
    },
  })

  function getProposalDetails(): any[] {
    let contractReadArray = []
    for (let i = 1; i <= daoInfo['Total Proposals']; i++) {
      contractReadArray.push({
        ...daoContract,
        functionName: 'proposals',
        args: [i],
      })
      contractReadArray.push({
        ...daoContract,
        functionName: 'getProposalArrays',
        args: [i],
      })
    }
    return contractReadArray
  }

  useContractReads({
    contracts: getProposalDetails(),
    enabled: daoInfo['Total Proposals'] > 0,
    onSuccess(data) {
      console.log(data)
      let proposalList: TProposal[] = []
      data.forEach((prpsl: any, index) => {
        if (index % 2 === 0) {
          proposalList.push({
            yesVotes: prpsl[2],
            noVotes: prpsl[3],
            totalVotes: prpsl[2] + prpsl[3],
            creationTime: prpsl[4],
            proposalType: ProposalType[prpsl[0]],
            proposer: prpsl[5],
            accounts: [],
            amounts: [],
            payloads: [],
          })
        } else {
          let prpl: TProposal = proposalList.pop() as TProposal
          let x: TProposal = {
            ...prpl,
            accounts: prpsl[0].map((ppl: any) => ppl.toString()),
            amounts: prpsl[1].map((ppl: any) => ppl.toString()),
            payloads: prpsl[2].map((ppl: any) => ppl.toString()),
          }
          proposalList.push(x)
        }
      })
      setProposals(proposalList)
    },
  })

  const submitVote = (proposalId: number, decision: boolean) => {
    setVoteProposal({ proposalId: proposalId, decision: decision })
  }

  useEffect(() => {
    voteProposal.proposalId > 0 && typeof voteProposal.decision === typeof Boolean && voteOnProposal?.()
  }, [voteProposal])

  const { config: mintConfig } = usePrepareContractWrite({
    address: networkMapping['43113'].ScholarDAO as `0x${string}`,
    abi: subdao_ABI,
    functionName: 'vote',
    args: [voteProposal.proposalId, voteProposal.decision],
    enabled: voteProposal.proposalId > 0 && typeof voteProposal.decision === typeof Boolean,
  })

  const { data: voteData, write: voteOnProposal, error: voteError } = useContractWrite(mintConfig)

  const { isLoading: isVoting } = useWaitForTransaction({
    hash: voteData?.hash,
    onSuccess(data) {
      // setReveal(true)
      console.log('Mint Trx: ', data)
      // const tokenId = Number(ethers.utils.formatUnits(data.logs[0].topics[3], 0))
    },
  })

  const proposalWriteArguments = (type: number): any[] => {
    let args: any[] = [0, ['0x'], ['0'], '0x']
    switch (type) {
      case 4:
        args = [4, [address], [ethers.utils.parseEther(newMintPrice)], ['0x']]
        break
      default:
        break
    }
    return args
  }

  const { config: submitProposalConfig } = usePrepareContractWrite({
    address: networkMapping['43113'].ScholarDAO as `0x${string}`,
    abi: subdao_ABI,
    functionName: 'propose',
    args: proposalWriteArguments(4),
  })

  const {
    data: submitProposalData,
    write: submitProposal,
    error: submitProposalError,
  } = useContractWrite(submitProposalConfig)

  const { isLoading: isProposalSubmitting } = useWaitForTransaction({
    hash: submitProposalData?.hash,
    onSuccess(data) {
      // setReveal(true)
      console.log('Proposal Submitted Trx Data: ', data)
      // const tokenId = Number(ethers.utils.formatUnits(data.logs[0].topics[3], 0))
    },
  })

  function getOwnershipPercent(): number {
    let totalSupply = parseFloat(daoInfo['Total Supply'].split(' ')[0])
    let userTokenCount = userTokens ? parseFloat(userTokens) : 0
    const ownershipPercentage = (userTokenCount / totalSupply) * 100
    return ownershipPercentage
  }

  return (
    <Box bg={useColorModeValue('gray.50', 'gray.800')} color={'gray.800'} minH="85vh">
      <Flex pb={12} pt={20} direction="column" justifyContent="space-evenly" alignItems="center">
        <Heading mb={6} color="primary.500" className={'btn-shine'}>
          Welcome to Scholar DAO!
        </Heading>

        <VStack mb={6} wrap={'wrap'}>
          <Text fontSize={{ base: 'sm', md: 'xl' }} mx={6} textAlign={'center'}>
            DAO Ownership: {getOwnershipPercent()} %
          </Text>
          <Text fontSize={{ base: 'xs', md: 'md' }} mx={6} textAlign={'center'}>
            {`Token Holdings: ${userTokens} ${daoInfo.Symbol}`}
          </Text>
        </VStack>
        <Button onClick={onToggle}>Dao Stats</Button>
        <Collapse in={isOpen} animateOpacity>
          <TableContainer>
            <Table size="sm">
              <Tbody>
                {Object.keys(daoInfo).map((daoInfokey) => {
                  return (
                    <Tr>
                      <Td>{daoInfokey}</Td>
                      <Td>{daoInfo[daoInfokey as keyof TDaoInfo]}</Td>
                    </Tr>
                  )
                })}
                <Tr>
                  <Td>Tresury Balance</Td>
                  <Td>
                    {data?.formatted} {data?.symbol}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Collapse>

        <Container>
          <Tabs size={'lg'} variant="solid-rounded" defaultIndex={0} align="center" pt={5}>
            <TabList mb="1em">
              <Tab
                whiteSpace={'nowrap'}
                border={'1px solid #48d7d4'}
                _selected={{
                  boxShadow: '3px 3px #48d7d4',
                }}
                me={'12px'}
              >
                Vote on Proposal
              </Tab>
              <Tab
                whiteSpace={'nowrap'}
                border={'1px solid #48d7d4'}
                _selected={{
                  boxShadow: '3px 3px #48d7d4',
                }}
                me={'12px'}
              >
                Create Proposal
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Stack
                  spacing={4}
                  w={'full'}
                  rounded={'xl'}
                  border={'1px solid #02c6c9'}
                  boxShadow={'5px 5px #02c6c9'}
                  p={6}
                  align={'center'}
                  mt={'20px'}
                >
                  <Heading textAlign={'left'}>Active Proposals</Heading>
                  <Accordion>
                    {proposals.map((prpsl, index) => {
                      return (
                        <AccordionItem>
                          <AccordionButton w={'full'}>
                            <Box as="span" flex="1" textAlign="left" minW={'320px'}>
                              Proposal {index + 1} <Tag mr={2}>{prpsl.proposalType}</Tag>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                          <AccordionPanel pb={4}>
                            <Stack alignItems={'flex-start'}>
                              <Text>
                                {' '}
                                Creation: <Tag mr={2}>{timeConverter(prpsl.creationTime)} </Tag>
                              </Text>
                              <Text>
                                Proposer:
                                <Tag mr={2}>
                                  {prpsl.proposer === networkMapping['43113'].HeadgearManager
                                    ? 'HGManger'
                                    : shortAddress(prpsl.proposer)}
                                </Tag>
                              </Text>
                              {prpsl.proposalType === 'MEMBERSHIP' && (
                                <>
                                  <Text>
                                    For:
                                    <Tag mr={2}>{prpsl.accounts.map((act) => shortAddress(act)).toString()}</Tag>
                                  </Text>
                                </>
                              )}
                              {prpsl.proposalType === 'PRICE' && (
                                <Text>
                                  New Mint Price: <Tag mr={2}>{ethers.utils.formatEther(prpsl.amounts[0])} Avax</Tag>
                                </Text>
                              )}
                              {/* <Text> Total Votes: {prpsl.totalVotes}</Text>
                            <Text> For: {prpsl.yesVotes}</Text>
                            <Text> Against: {prpsl.noVotes}</Text> */}
                              {prpsl.totalVotes > 0 && (
                                <Stat>
                                  <StatLabel mb={'5px'}>Total Votes: {prpsl.totalVotes}</StatLabel>
                                  <StatNumber mb={'5px'}>
                                    <StatLabel>{`${((prpsl.yesVotes * 100) / prpsl.totalVotes).toFixed(
                                      2
                                    )}%`}</StatLabel>
                                    <Progress
                                      value={(prpsl.yesVotes * 100) / prpsl.totalVotes}
                                      size="lg"
                                      colorScheme="teal"
                                      borderRadius={'10px'}
                                    />
                                  </StatNumber>
                                  <StatNumber mb={'5px'}>
                                    <StatLabel>{`${((prpsl.noVotes * 100) / prpsl.totalVotes).toFixed(2)}%`}</StatLabel>
                                    <Progress
                                      value={(prpsl.noVotes * 100) / prpsl.totalVotes}
                                      size="lg"
                                      colorScheme="red"
                                      borderRadius={'10px'}
                                    />
                                  </StatNumber>
                                </Stat>
                              )}
                            </Stack>
                            <Text>{voteError?.message}</Text>
                            {isVoting ? (
                              <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
                            ) : (
                              <HStack justify={'space-evenly'} mt={'4'}>
                                <Button colorScheme="teal" onClick={(e) => submitVote(index + 1, true)}>
                                  Accept
                                </Button>
                                <Button colorScheme="red" onClick={(e) => submitVote(index + 1, false)}>
                                  Reject
                                </Button>
                              </HStack>
                            )}
                          </AccordionPanel>
                        </AccordionItem>
                      )
                    })}
                  </Accordion>
                </Stack>
              </TabPanel>
              <TabPanel>
                <Stack
                  spacing={4}
                  w={'full'}
                  rounded={'xl'}
                  border={'1px solid #02c6c9'}
                  boxShadow={'5px 5px #02c6c9'}
                  p={6}
                  align={'center'}
                  mt={'20px'}
                >
                  <Heading textAlign={'left'}>Create Proposal</Heading>
                  {/* <form>
                    <FormControl>
                      <FormLabel>Proposal Type</FormLabel>
                      <Select placeholder="Select option">
                        <option value="MEMBERSHIP">Add New Member</option>
                        <option value="SHARES">Distribute Ownership Shares</option>
                        <option value="CALL">Call Contract</option>
                        <option value="EXTENSION">Enable New Extension</option>
                        <option value="PRICE">Set Membership Price</option>
                        <option value="ESCAPE">Delete Pending Proposal</option>
                        <option value="LORE">Propose New Lore</option>
                      </Select>
                    </FormControl>
                    <Button type={'submit'}>Submit Proposal</Button>
                  </form> */}
                  <Accordion>
                    <AccordionItem>
                      <AccordionButton w={'full'}>
                        <Box as="span" flex="1" textAlign="left" minW={'320px'}>
                          Update Headgear Mint Price
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        <form>
                          <FormControl>
                            <FormLabel> New Mint Price </FormLabel>
                            <Input
                              type="string"
                              value={newMintPrice}
                              onChange={(e) => setNewMintPrice(e.target.value)}
                            ></Input>
                          </FormControl>
                          <HStack justify={'space-evenly'} mt={'4'}>
                            <Button colorScheme="teal" onClick={(e) => submitProposal?.()}>
                              Submit New Mint Price Proposal
                            </Button>
                          </HStack>
                        </form>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton w={'full'}>
                        <Box as="span" flex="1" textAlign="left" minW={'320px'}>
                          Update Headgear Mint Price
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        <form>
                          <FormControl>
                            <FormLabel> New Mint Price </FormLabel>
                            <Input
                              type="string"
                              value={newMintPrice}
                              onChange={(e) => setNewMintPrice(e.target.value)}
                            ></Input>
                          </FormControl>
                          <HStack justify={'space-evenly'} mt={'4'}>
                            <Button colorScheme="teal" onClick={(e) => submitProposal?.()}>
                              Submit New Mint Price Proposal
                            </Button>
                          </HStack>
                        </form>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Stack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
      </Flex>
    </Box>
  )
}
export default ScholarSubDao

// enum ProposalType {
//   MEMBERSHIP, // call headgear manager to process membership request
//   SHARES, // distribute ownership shares
//   CALL, // call contracts
//   EXTENSION, // set or enable an extension
//   PRICE, // set `membershipMintPrice`
//   ESCAPE, // delete pending proposal in case of revert
//   LORE, // propose lore change to main dao
// }
