import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  useColorMode,
  Heading,
} from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon, ChevronDownIcon, MoonIcon, SunIcon } from '@chakra-ui/icons'
import { FaTwitter, FaDiscord, FaMediumM, FaVolumeMute, FaVolumeUp } from 'react-icons/fa'
import { useState, useRef, useEffect } from 'react'
import { ConnectButton } from '@rainbow-me/rainbowkit'

export default function Header() {
  const { isOpen, onToggle } = useDisclosure()
  const { colorMode, toggleColorMode } = useColorMode()
  const [isplaying, setisplaying] = useState(false)
  const audioElem = useRef<HTMLAudioElement>(null)
  const theme = 'https://ipfs.io/ipfs/bafybeieiowsp7cbmeg2meeh6lpp4ifavbppwxmfrwczlon5nwtfvfj2yjq/ak3demix_theme.mp3'

  useEffect(() => {
    if (!isplaying) {
      //@ts-ignore
      audioElem.current.pause()
    } else {
      //@ts-ignore
      audioElem.current.play()
    }
  }, [isplaying])

  const toggleAudio = () => {
    setisplaying(!isplaying)
  }

  return (
    <Box minH={'10'} className={'sticky'}>
      <Flex
        bg={useColorModeValue('gray.50', 'gray.800')}
        color={useColorModeValue('gray.800', 'gray.50')}
        minH={'60px'}
        width="100%"
        py={{ base: 0 }}
        px={{ base: 4 }}
        borderBottom={2}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.100', 'gray.700')}
        align={'center'}
      >
        <Flex flex={{ base: 1, md: 'auto' }} ml={{ base: -2 }} width="100%" display={{ base: 'flex', md: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: 2 }} justify={{ base: 'center', md: 'start' }}>
          <a href={'/'}>
            <Heading
              textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
              color={useColorModeValue('gray.800', 'gray.50')}
            >
              {/* <Image src={logo} width={'70px'} /> */}
              AK3DEMIX
            </Heading>
          </a>
          <Flex display={{ base: 'none', md: 'flex' }} ml={5}>
            <DesktopNav />
          </Flex>
        </Flex>
        <Stack flex={{ base: 1, md: 2 }} justify={'flex-end'} direction={'row'} spacing={{ base: 1, md: 4 }}>
          <IconButton
            display={{ base: 'none', lg: 'inherit' }}
            as={'a'}
            target="_blank"
            href={'https://medium.com/@ak3demia'}
            icon={<FaMediumM />}
            aria-label={'Toggle Mode'}
          />
          <IconButton
            display={{ base: 'none', lg: 'inherit' }}
            as={'a'}
            target="_blank"
            href={'https://twitter.com/ak3demix'}
            icon={<FaTwitter />}
            aria-label={'Toggle Mode'}
          />
          <IconButton
            display={{ base: 'none', lg: 'inherit' }}
            as={'a'}
            target="_blank"
            href={'https://discord.gg/Hsz25xsDbx'}
            icon={<FaDiscord />}
            aria-label={'Toggle Mode'}
          />
          <audio src={theme} ref={audioElem} loop />
          <IconButton
            onClick={toggleAudio}
            as={isplaying ? FaVolumeUp : FaVolumeMute}
            aria-label={'Toggle Music'}
            borderRadius={'full'}
            border={'2px solid #48d7d4'}
            p={'9px'}
          />
          <IconButton
            onClick={toggleColorMode}
            icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
            aria-label={'Toggle Mode'}
            borderRadius={'full'}
            border={'2px solid #48d7d4'}
          />{' '}
          <Box>
            <ConnectButton label="Connect" />{' '}
          </Box>
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  )
}

const DesktopNav = () => {
  const linkColor = useColorModeValue('gray.800', 'gray.200')
  const linkHoverColor = useColorModeValue('gray.800', 'gray.50')
  const popoverContentBgColor = useColorModeValue('gray.50', 'gray.800')

  return (
    <Stack direction={'row'} spacing={4} alignItems={'center'}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label} alignItems={'center'} justifyItems={'center'}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                href={navItem.href ?? '#'}
                fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}
                fontWeight={400}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}
                target={`${navItem.isExternal ? '_blank' : '_self'}`}
                whiteSpace={'nowrap'}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent border={0} boxShadow={'xl'} bg={popoverContentBgColor} p={4} rounded={'xl'} minW={'sm'}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  )
}

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Link
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('primary.50', 'gray.900') }}
    >
      <Text transition={'all .3s ease'} _groupHover={{ color: 'primary.400' }} fontWeight={400} noOfLines={1}>
        {label}
      </Text>
    </Link>
  )
}

const MobileNav = () => {
  return (
    <Stack bg={useColorModeValue('gray.50', 'gray.800')} p={4} display={{ md: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  )
}

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? '#'}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}
      >
        <Text fontWeight={800} color={useColorModeValue('gray.800', 'gray.200')}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.800')}
          align={'start'}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href} target={`${child.isExternal ? '_blank' : '_self'}`}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  )
}

type NavItem = {
  label: string
  subLabel?: string
  children?: Array<NavItem>
  href?: string
  isExternal: boolean
}

const NAV_ITEMS: Array<NavItem> = [
  // {
  //   label: 'Test Navigation',
  //   children: [
  //     {
  //       label: 'Item 1',
  //       subLabel: 'Item1 Description',
  //       href: '/link1',
  //     },
  //     {
  //       label: 'Item 2',
  //       subLabel: 'Item2 Description',
  //       href: '/lin2',
  //     },
  //   ],
  // },
  // {
  //   label: 'Academix',
  //   href: '/academix',
  //   isExternal: false,
  // },
  {
    label: 'Mint',
    href: '/mint',
    isExternal: false,
  },
  {
    label: 'Lore',
    href: '/lore',
    isExternal: false,
  },
  {
    label: 'Multiple Intelligence',
    href: '/multiple-intelligence',
    isExternal: false,
  },
  // {
  //   label: 'Free Mint',
  //   href: '/freemint',
  //   isExternal: false,
  // },

  {
    label: 'Previous Collections',
    href: '/previous-collections',
    isExternal: false,
  },
  // {
  //   label: 'Litepaper',
  //   href: 'https://ak3demia.gitbook.io/ak3demix/',
  //   isExternal: true,
  // },
  {
    label: 'Supreme Council',
    href: '/supreme-council',
    isExternal: false,
  },

  {
    label: 'Roadmap',
    href: '/roadmap',
    isExternal: false,
  },
]
