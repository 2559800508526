import { Button, Flex, Heading, Image, Stack, Text } from '@chakra-ui/react'

export type CollectionCardProps = {
  image: string
  title: string
  description: string
  collectionUrl: string
  infoUrl?: string
}

export default function CollectionCard({ image, title, description, collectionUrl, infoUrl }: CollectionCardProps) {
  return (
    <Stack direction={{ base: 'column', md: 'row' }} my={'25px'}>
      <Flex p={8} flex={1} align={'center'} justify={'center'}>
        <Stack spacing={6} w={'full'} maxW={'lg'}>
          <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>{title}</Heading>
          <Text fontSize={{ base: 'md', lg: 'lg' }} color={'gray.500'}>
            {description}
          </Text>
          <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
            <Button
              as={'a'}
              target="_blank"
              rounded={'full'}
              bg={'primary.500'}
              color={'white'}
              _hover={{
                bg: 'black',
              }}
              href={collectionUrl}
            >
              View Collection
            </Button>
            {infoUrl && (
              <Button
                as={'a'}
                target="_blank"
                rounded={'full'}
                bg={'primary.500'}
                color={'white'}
                _hover={{
                  bg: 'black',
                }}
                href={infoUrl}
              >
                More Info
              </Button>
            )}
          </Stack>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image alt={'Collection Image'} objectFit={'contain'} boxSize={{ base: 'sm', md: 'lg' }} src={image} />
      </Flex>
    </Stack>
  )
}
