import { BigNumber, ethers } from 'ethers'
import { MembershipRequest } from '../types/MembershipRequst.type'
import axios from 'axios'

enum SubDao {
  NONE,
  NEOPHYTE,
  SCHOLAR,
  HOOD,
  ROYAL,
}

const TOKEN_OFFSET = 8 // Define the TOKEN_OFFSET value
const PROPOSAL_OFFSET = 24 // Define the PROPOSAL_OFFSET value
const ESCROWED_FUNDS_OFFSET = 56 // Define the ESCROWED_FUNDS_OFFSET value

export function shortAddress(address: string | `0x${string}` | undefined): string {
  if (address) {
    return `${address.slice(0, 6)}...${address.slice(address.length - 4)}`
  } else {
    return '0x0000...0000'
  }
}

export function isValidETHAddress(address: string): boolean {
  return ethers.utils.isAddress(address)
}

export function isValidSHA256(hashString: string): boolean {
  const regexExp = /^[a-f0-9]{64}$/gi

  let testString = hashString.length === 66 && hashString.slice(0, 2) === '0x' ? hashString.slice(2) : hashString

  return regexExp.test(testString)
}

export function getBase64(file: any) {
  return new Promise((resolve) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result as string)
    }
  })
}

export function stringToHex(str: string): string {
  return ethers.utils.hexlify(ethers.utils.toUtf8Bytes(str))
}

export function hexToString(hex: string): string {
  return ethers.utils.toUtf8String(hex)
}

export function timeConverter(UNIX_timestamp: any): string {
  var a = new Date(UNIX_timestamp * 1000)
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  var year = a.getFullYear()
  var month = months[a.getMonth()]
  var date = String(a.getDate()).padStart(2, '0')
  var hour = String(a.getHours()).padStart(2, '0')
  var min = String(a.getMinutes()).padStart(2, '0')
  // var sec = a.getSeconds()
  var time = `${hour}:${min} hrs on ${date}-${month}`
  return time
}

export function decodeMembershipRequest(encoded: BigNumber): MembershipRequest {
  const subDao: BigNumber = encoded.and(0xff)
  const tokenId: BigNumber = encoded.shr(TOKEN_OFFSET).and(0xffff)
  const proposalId: BigNumber = encoded.shr(PROPOSAL_OFFSET).and(0xffffffff)
  const escrowedAmount: BigNumber = encoded.shr(ESCROWED_FUNDS_OFFSET)
  const membershipReqDetails = {
    subDao: SubDao[Number(subDao.toString())],
    tokenId: tokenId.toString(),
    proposalId: proposalId.toString(),
    escrowedAmount: ethers.utils.formatUnits(escrowedAmount.toString(), 'ether'),
  }
  console.log('Membership Request Details')
  console.table(membershipReqDetails)
  return membershipReqDetails
}

export const getABIEtherscan = async (address: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SNOWTRACE_TESTNET_API_URL}?module=contract&action=getabi&address=${address}&apikey=${process.env.REACT_APP_SNOWTRACE_API_KEY}`
    )
    return JSON.parse(response.data.result)
  } catch (e: any) {
    console.log(`e`, e.message)
  }
}

// export const fromCidV1ToV0 = (v1Data: string) => {
//   let v1 = CID.parse(v1Data)
//   return v1.toV0().toString()
// }

// export const fromCidV0ToV1 = (v0Data: string) => {
//   let v0 = CID.parse(v0Data)
//   return v0.toV1().toString()
// }

// export const fromIPFSCID = (cid: string) => {
//   const bytes = base58.decode(cid)
//   const multiHashId = 2
//   return bytes.slice(multiHashId, bytes.length)
// }

// export const toIPFSCID = (str: string) => {
//   // remove leading 0x
//   const remove0x = str.slice(2, str.length)
//   // add back the multihash id
//   const bytes = Buffer.from(`1220${remove0x}`, 'hex')
//   const hash = base58.encode(bytes)
//   return hash
// }
